/** @format */

import React from "react";


const Press = props => {
  const News = [
    {
      imgUrl: "logo-benzinga.png",
      link: "https://www.benzinga.com/amp/content/32615689",
    },
    {
      imgUrl: "m1.png",
      link: "https://finance.yahoo.com/news/oscarswap-com-future-dexs-yield-170000663.html",
    },
    {
      imgUrl: "logoBBGwht-4230a564d3.svg",
      link: "https://www.bloomberg.com/press-releases/2023-05-29/oscarswap-com-is-here-the-future-of-dexs-and-yield-farming-on-arbitrum",
    },
    {
      imgUrl: "p6.png",
      link: "https://www.marketwatch.com/press-release/oscarswap-the-future-of-decentralized-exchanges-and-yield-farming-on-arbitrum-2023-05-30?mod=search_headline",
    },
    {
      imgUrl: "Weekly-Malaysia-Logo-black-300x53.png",
      link: "https://weeklymalaysia.com/oscarswap-the-future-of-decentralized-exchanges-and-yield-farming-on-arbitrum/",
    },
    {
      imgUrl: "p2.png",
      link: "https://apnews.com/press-release/globe-newswire/oscars-globe-newswire-business-482c89d2317b2b9e6f65d4fffac62480",
    },
    {
      imgUrl: "morningstar-50px.d304f16.svg",
      link: "https://www.morningstar.com/news/globe-newswire/8848278/oscarswapcom-is-here-the-future-of-dexs-and-yield-farming-on-arbitrum",
    },
    {
      imgUrl: "Street-Inside.e08c67c70d6b2120d672.png",
      link: "https://www.streetinsider.com/Globe+Newswire/Oscarswap.com+Is+Here%3A+The+Future+of+DEXs+and+Yield+Farming+on+Arbitrum/21730088.html",
    },
    {
      imgUrl:
        "https://s3.amazonaws.com/images.investorsobserver.com/io-logo-300-black-dark.svg",
      link: "https://www.investorsobserver.com/news/qm-pr/8668798945878405",
    },
    {
      imgUrl: "cropped-KSNT-Dot-ComV3-USE.webp",
      link: "https://www.ksnt.com/business/press-releases/globenewswire/8848278/oscarswap-com-is-here-the-future-of-dexs-and-yield-farming-on-arbitrum/",
    },
    {
      imgUrl: "cryptoadventure-logo-white.svg",
      link: "https://cryptoadventure.com/oscarswap-com-is-here-the-future-of-dexs-and-yield-farming-on-arbitrum/",
    },
    {
      imgUrl: "cropped-FOX2NOW.webp",
      link: "https://fox2now.com/business/press-releases/globenewswire/8848278/oscarswap-com-is-here-the-future-of-dexs-and-yield-farming-on-arbitrum/",
    },
    // {
    //   imgUrl: " ",
    //   link: "",
    // },
  ];

  return (
    <div className='container-fluid news '>
      <marquee behavior='smooth' direction='left' >
        <div className='row'>
       

          {News.map((v, i) => {
            return (
              <div className='col ' key={i}>
                <div className='img-box'>
                  <a href={v.link} target='_blank' rel='noopener noreferrer'>
                    <img src={v.imgUrl} className='w-75 ' alt='' />
                  </a>
                </div>
              </div>
            );
          })}

        
        </div>
      </marquee>
    </div>
  );
};

export default Press;
