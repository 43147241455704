import random from "lodash/random";

// List of available rpc nodes to connect to
export const rpcNodes = [
  "https://arb1.arbitrum.io/rpc/",
  "https://arb1.arbitrum.io/rpc/",
  "https://arb1.arbitrum.io/rpc/",
];

export const getRpcUrl = () => {
  const randomIndex = random(0, rpcNodes.length - 1);
  return rpcNodes[randomIndex];
};
