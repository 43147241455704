import React, { useState } from "react";

export default function CountDown({liveIn}) {
    const [days, setdays] = useState(null);
    const [hours, sethours] = useState(null);
    const [minutes, setminutes] = useState(null);
    const [seconds, setseconds] = useState(null);
  
    var countDownDate = new Date(liveIn)
    var GetIme = new Date(countDownDate).getTime();

  

    setInterval(() => {
      var now = new Date().getTime();
      var distance = GetIme - now;
      var day = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hour = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var second = Math.floor((distance % (1000 * 60)) / 1000);
      setdays(day);
      sethours(hour);
      setminutes(minute);
      setseconds(second);
    }, 1000);

    return (
        <React.Fragment>
            {seconds > -1 &&
                <span className="m-auto timer    d-block" >
                    <span id="day">
                    <strong>{days}</strong>  
                        <small >DAYS &nbsp;</small>
                    </span>
                    <span id="hours">
                        <strong>{hours}</strong> 
                        <small>HOURS</small>
                    </span>
                    <span id="minutes">
                        <strong>{minutes}</strong> 
                        <small>MINUTES</small>
                    </span>
                    <span id="second">
                        <strong>{seconds}</strong>
                        <small>SECONDS</small>
                    </span>
                </span>
            }
        </React.Fragment>
    )
}