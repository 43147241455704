/** @format */

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import WalletsDialog from "@components/WalletsDialog";
import Home from "./Home";
import "./App.scss";
import React from "react";
import { Nav, OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Press from "./components/Press";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import ReactGA from "react-ga";
ReactGA.initialize("UA-261507667-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const COMMIT_VERSION = process.env.REACT_APP_COMMIT_VERSION;

const Version = withStyles(() => ({
  root: {
    position: "absolute",
    right: 12,
    bottom: 0,
    color: "#666",
  },
}))(Typography);
const CountDown = withStyles(() => ({
  root: {
    color: "#666",
    fontSize: "60px",
    textAlign: "center",
  },
}))(Typography);
const Completionist = () => <>Spot Trading Platform is Live Now!</>;

function App() {
  return (
    <>
      <div className='App'>
      {/* <Home /> */}
        <Press />
        <Header />
        <Tab.Container
          id='left-tabs-example'
          // className='m-auto'
          defaultActiveKey='ARBITRUM'>
          <Nav variant='pills' className='flex-row  m-auto nav-img'>
          
            <Nav.Item className='m-auto'>
              <Nav.Link href='https://stakebsc.oscarswap.com' target='_blank'>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip>Binance Smart Chain (Live Soon)</Tooltip>
                  }>
                  <img src='bnb.png' width={80} className='mx-2' alt='' />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='m-auto'>
              <Nav.Link eventKey='ARBITRUM'>
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip>ARBITRUM (Live)</Tooltip>}>
                  <img
                    src='arb.png'
                    className='mx-2 img-fade'
                    width={80}
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='m-auto'>
              <Nav.Link href='https://stakeeth.oscarswap.com' target='_blank'>
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip>Ethereum (Coming Soon)</Tooltip>}>
                  <img
                    src='eth.png'
                    className='mx-2 img-fade'
                    width={80}
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
          <Home />
         
            {/* <Tab.Pane eventKey='Binance'>
              <iframe
                title='stake'
                src='https://stakebsc.oscarswap.com'
                width='100%'
                height='590px'></iframe>
            </Tab.Pane>
            <Tab.Pane eventKey='ARBITRUM'>
            </Tab.Pane> */}
          </Tab.Content>
        </Tab.Container>
        <Footer />
        {/* {COMMIT_VERSION ? (
          <Version variant='caption'>Version: {COMMIT_VERSION}</Version>
        ) : null} */}
      </div>
      <WalletsDialog />
    </>
  );
}

export default App;
