/** @format */

import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRequest from "@ahooksjs/use-request";
import StakeCard, { StakeCardProps } from "@components/StakeCard";
import { Token, TOKEN_ADDRESS, Action } from "@utils/constants";
import useWallet from "@hooks/useWallet";
import useFetchStats from "@hooks/useFetchStats";
import useFetchState from "@hooks/useFetchState";
import arbBg from "@assets/bgs/arb.png";
import CountDown from "./Countdown";

const useStyles = makeStyles({
  container: {
    padding: "6vh 24px 24px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  card: {
    margin: 10,
  },
});

const cards: StakeCardProps[] = [
  {
    token: Token.JIT,
    bg: arbBg,
    color: "#ef5350",
    // open: false,
    pendingText: (
      <>
        <h4 className='h44'>
          <span> Start in </span> <br />{" "}
          <CountDown liveIn='Jun 1, 2023 19:40:00 UTC' />
        </h4>
      </>
    ),
    cardTitle: (
      <>
        <img
          src='oscar.png'
          alt=''
          style={{ width: "28px", marginBottom: "4px", marginRight: "8px" }}
        />
        Pool 1
      </>
    ),
    desc: (
      <>
        <br />
        No lockup (Adjustable)
      </>
    ),
  },
  {
    token: Token.JIT_OSCAR_ARB,
    bg: arbBg,
    color: "#ef5350",
    // open: false,
    pendingText: (
      <>
        <h4 className='h44'>
          <span> Start in </span> <br />{" "}
          <CountDown liveIn='Jun 1, 2023 20:30:00 UTC' />
        </h4>
      </>
    ),
    cardTitle: (
      <>
        <img
          src='oscar.png'
          alt=''
          style={{ width: "28px", marginBottom: "4px", marginRight: "8px" }}
        />
        Pool 2
      </>
    ),
    desc: (
      <>
        <br />1 week locking period
      </>
    ),
  },
  // {
  //   token: Token.JIT_OSCAR,
  //   bg: arbBg,
  //   color: "#ef5350",
  //   open: false,
  //   pendingText: (
  //     <>
  //       <h4 className='h44'>
  //         <span> Live Soon </span>
  //         {/* <br /> <CountDown liveIn='Jun 2, 2023 16:00:00 UTC' /> */}
  //       </h4>
  //     </>
  //   ),
  //   cardTitle: (
  //     <>
  //       <img
  //         src='oscar.png'
  //         alt=''
  //         style={{ width: "28px", marginBottom: "4px", marginRight: "8px" }}
  //       />
  //       Pool 3
  //     </>
  //   ),
  //   desc: (
  //     <>
  //       <br />4 week locking period{" "}
  //     </>
  //   ),
  // },
  // {
  //   token: Token.JIT_OSCAR,
  //   bg: arbBg,
  //   color: "#ef5350",
  //   open: false,
  //   pendingText: (
  //     <>
  //       <h4 className='h44'>
  //         <span> Live Soon </span> <br />{" "}
  //         {/* <CountDown liveIn='Jun 2, 2023 19:30:00 UTC' /> */}
  //       </h4>
  //     </>
  //   ),
  //   cardTitle: (
  //     <>
  //       <img
  //         src='oscar.png'
  //         alt=''
  //         style={{ width: "28px", marginBottom: "4px", marginRight: "8px" }}
  //       />
  //       Pool 4
  //     </>
  //   ),
  //   desc: (
  //     <>
  //       <br />8 week locking period{" "}
  //     </>
  //   ),
  // },
];

export default function Home() {
  const classes = useStyles();

  const { connected } = useWallet();

  const fetchStats = useFetchStats();
  const fetchState = useFetchState();

  useRequest(fetchStats, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
  });

  const { run, cancel } = useRequest(fetchState, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
  });

  useEffect(() => {
    if (connected) {
      run();
    } else {
      cancel();
    }
  }, [cancel, connected, run]);

  return (
    <div className={classes.container}>
      {cards.map(card => (
        <StakeCard key={card.token} {...card} className={classes.card} />
      ))}
    </div>
  );
}
